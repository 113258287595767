import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'

import { useAuthMutation } from 'store/slices/auth/authApiSlice'
import { notify } from 'utils/notify'
import Button from 'components/ui/button'

import styles from '../auth.module.scss'

const EmailForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    register,
    getValues,
    reset,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting, isValid, isLoading },
  } = useForm({
    defaultValues: {
      email: '',
    },
  })

  const [auth, { data: authData, isLoading: isAuthLoading, isError: isAuthError }] =
    useAuthMutation()

  const onSubmit = async (data) => {
    try {
      const { email } = data
      if (email) {
        const newEmail = email.toLowerCase().trim()
        await auth({ email: newEmail }).unwrap()
      }
    } catch (error) {
      console.log(error)
      const { data } = error
      notify(`${data?.message || 'Непредвиденная ошибка'}`, 'error')
      data?.errors?.map((err) =>
        setError(err.path, {
          message: err.msg,
        }),
      )
    }
  }

  useEffect(() => {
    if (authData) {
      const { requestID } = authData
      notify('Код отправлен', 'success')
      navigate(`/auth/check?requestID=${requestID}`, { state: location?.state })
    }
  }, [authData])

  return (
    <>
      <form
        className={clsx(styles.form)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={clsx(styles.title)}>
          <h3>Регистрация</h3>
        </div>
        <div className={clsx(styles.inputs)}>
          <div className={clsx(styles.form_control)}>
            <label htmlFor='emailID'>
              <span>
                Почта <b>*</b>
              </span>
              <input
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Это поле обязательно',
                  },
                })}
                id='emailID'
                type='email'
                placeholder='user@email.com'
              />
              {errors['email'] && <span className={styles.error}>{errors['email'].message}</span>}
            </label>
          </div>
          <div className={clsx(styles.form_control)}>
            <Button
              disabled={false}
              loading={isAuthLoading}
              type={'submit'}
              className={`btn prime fw ${styles.submit}`}
            >
              Далее
            </Button>
          </div>
        </div>
      </form>
      <div className={styles.footer}>
        <div className={styles.footer_item}>
          <p>
            Нажимая "Далее" Вы соглашаетесь с{' '}
            <Link to={'/privacy'}>
              политикой конфиденциальности и правилами обработки персональных данных
            </Link>
          </p>
        </div>
        <div className={styles.footer_item}>
          <p>
            <Link to={'login'}>Войти по логину и паролю</Link>
          </p>
        </div>
      </div>
    </>
  )
}
export default EmailForm
