import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentUser } from '../../store/slices/auth/authSlice'

export const ProtectedRoute = ({ children, ...rest }) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)

  const state = {
    from: location,
  }

  return user ? (
    <Outlet />
  ) : (
    <Navigate
      to='/auth/login'
      state={state}
      replace={true}
    />
  )
}
