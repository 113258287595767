import React, { useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import FsLightbox from 'fslightbox-react'

import { selectRates } from '../../store/slices/rate/rateSlice'
import { selectCurrentUser } from '../../store/slices/auth/authSlice'

import { notify } from '../../utils/notify'

import Button from '../../components/ui/button'
import Countdown from '../../components/ui/countdown'
import ParolaxContainer from 'components/parolax'
import Rates from '../../components/ui/rates'

import bgVideo from '../../assets/videos/trailer.mp4'
import trailerPreview1 from '../../assets/img/covers/cover1.jpg'
import trailerPreview2 from '../../assets/img/covers/cover2.jpg'
import mobileBG from '../../assets/img/intro-mobile-bg.png'
import aboutBg from 'assets/img/promo/bg-3.png'

import styles from './home.module.scss'
import { selectActiveSubscription } from 'store/slices/subscription/subscriptionSlice'

const videosTrailers = [
  {
    preview: trailerPreview1,
    src: '/trailer1.mp4',
    title: 'Трейлер №1',
  },
  {
    preview: trailerPreview2,
    src: '/trailer2.mp4',
    title: 'Трейлер №2',
  },
]

const HomePage = ({ isLoading, isError }) => {
  const [lightboxControllerVideos, setLightboxControllerVideos] = useState({
    toggler: false,
    slide: 1,
  })
  const user = useSelector(selectCurrentUser)
  const activeSubscription = useSelector(selectActiveSubscription)

  const openLightboxOnSlide = (slideIndex, slideTate, setSlideState) => {
    setSlideState({
      toggler: !slideTate.toggler,
      slide: slideIndex,
    })
  }

  const videoTrailersSrcArray = videosTrailers.map((el, i) => (
    <div
      className={styles.video_wrapper}
      style={{ width: '85%', height: 'auto' }}
    >
      <video
        autoPlay={i + 1 === lightboxControllerVideos.slide ? true : false}
        controls
        controlsList='nodownload'
      >
        <source
          src={el.src}
          type={'video/mp4'}
        />
      </video>
    </div>
  ))

  return (
    <>
      <section className={styles.intro}>
        <div className={styles.intro_bg}>
          <video
            autoPlay
            loop
            muted
            playsInline
            poster={trailerPreview1}
            className={styles.intro_bg_video}
          >
            <source
              src={bgVideo}
              type='video/mp4'
            ></source>
          </video>
          <div className={styles.intro_bg_img}>
            <img
              src={mobileBG}
              alt='background'
            />
          </div>
          <div className={styles.intro_overlay}></div>
        </div>
        <div className={styles.content}>
          <div className={styles.info}>
            <div className={styles.text}>
              <h1>
                <span>Герой</span>
                <br />
                (Народный Анимационный Фильм) 18+
              </h1>
              <p>
                От автора - <b>Красовского Александра:</b>
              </p>
              <p>
                ‟ Фильм «Герой» первый независимый фильм в России. Мы создавали его своими силами и
                средствами по мотивам моей книги. В общей сложности создание фильма заняло
                приблизительно 5 лет. Было несколько провальных попыток, но мы не сдавались, не
                опускали руки! Сохраняя энтузиазм, мы стремились к цели доделать этот проект. Теперь
                Вы можете судить, что у нас получилось. Приятного просмотра! ”
              </p>
              <p>Кто они – настоящие герои?</p>
            </div>
            <div className={styles.actions}>
              {!user ? (
                <>
                  <Link
                    className={'btn prime'}
                    to='/auth/login'
                  >
                    <span>Хочу узнать</span>
                  </Link>
                </>
              ) : (
                <Link
                  className={'btn prime'}
                  to='/me'
                >
                  К просмотру
                </Link>
              )}
            </div>
          </div>
          <div className={styles.video}>
            {videosTrailers.map((trailer, i) => {
              return (
                <div
                  key={i}
                  className={clsx(styles.video_item)}
                  onClick={() =>
                    openLightboxOnSlide(
                      i + 1,
                      lightboxControllerVideos,
                      setLightboxControllerVideos,
                    )
                  }
                >
                  <div className={clsx(styles.video_item_img)}>
                    <img
                      src={trailer.preview}
                      alt={trailer.title}
                    />
                  </div>
                  <div className={styles.video_item_text}>
                    <p>{trailer.title}</p>
                  </div>
                  <div className={styles.video_item_play}>
                    <svg
                      width='60'
                      height='60'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM2 10.172C2.04732 14.5732 5.64111 18.1095 10.0425 18.086C14.444 18.0622 17.9995 14.4875 17.9995 10.086C17.9995 5.68451 14.444 2.10977 10.0425 2.086C5.64111 2.06246 2.04732 5.59876 2 10V10.172ZM8 14.5V5.5L14 10L8 14.5Z' />
                    </svg>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={styles.countdown}>
            <div className={styles.countdown_text}>
              <h3>Для просмотра доступен весь первый сезон</h3>
              <Link
                className={`${styles.countdown_watch}`}
                to='/watch'
              >
                Смотреть &#8594;
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.about}>
        <ParolaxContainer bgImage={aboutBg}>
          <div className={styles.about_container}>
            <p>
              Главный герой криминальной комедии бандит - Юрич, со своими чувством справедливости и
              принципами.
            </p>
            <p>
              В провинциальном городе главный герой, не раз побывавший в тюрьме за ограбления, и его
              напарники помогают другу - первому заместителю мэра осуществить месть и заработать
              крупную сумму денег.
            </p>
            <p>
              История закручивается и в череде случайности и нелепых совпадений, происходят события,
              которые приводят всех к неожиданному финалу.
            </p>
            <p>
              Чем же закончится эта игра за справедливость, в которую оказывается втянут весь город.
            </p>
          </div>
        </ParolaxContainer>
      </section>
      <section className={styles.information}>
        <div className={styles.information_container}>
          <div className={styles.information_block}>
            <div className={styles.information_title}>
              <h4>Как смотреть?</h4>
            </div>
            <div className={styles.information_content}>
              <p>
                Сериал "Герой" доступен для просмотра на нашем сайте в личном кабинете после
                регистрации
              </p>
              <p>
                <b>Важно:</b> У каждой серии есть дата премьеры, это значит, что она не будет
                доступна для просмотра до назначенной даты
              </p>
              <p>
                Просмотр доступен со всех современных браузеров последних версий (Chrome, Safari,
                FireFox, Yandex Browser)
              </p>
            </div>
          </div>
          <div className={styles.information_block}>
            <div className={styles.information_title}>
              <h4>Поддержать автора</h4>
            </div>
            <div className={styles.information_content}>
              <p>
                Вы можете поддержать автора в создании следующих эпизодов сериала сделав
                добровольное пожертвование
              </p>
              <p>
                Красовский Александр <b>2202 2023 6464 3100</b> (Sberbank)
              </p>
            </div>
          </div>
        </div>
      </section>

      <FsLightbox
        type='video'
        toggler={lightboxControllerVideos.toggler}
        slide={lightboxControllerVideos.slide}
        sources={videoTrailersSrcArray}
        // customAttributes={[
        //   {
        //     autoPlay: true,
        //     controls: true,
        //     controlsList: 'nodownload',
        //   },
        // ]}
      />
    </>
  )
}

export default HomePage
