import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import FsLightbox from 'fslightbox-react'

import { selectCurrentUser } from '../../../store/slices/auth/authSlice'

import { notify } from '../../../utils/notify'

import Button from '../button'

import buyIcon from '../../../assets/img/buy.svg'
import playIcon from '../../../assets/img/play-white.svg'
import eyeIcon from '../../../assets/img/watched.svg'

import styles from './card.module.scss'
import VideoPlayer from '../video-player'
import Views from '../views'
import { selectActiveSubscription } from '../../../store/slices/subscription/subscriptionSlice'

const Card = ({
  poster,
  title,
  subTitle,
  description,
  price,
  views,
  fileName,
  fileType,
  premierDate,
  _id,
  className,
  watchHandler,
}) => {
  const activeSubscription = useSelector(selectActiveSubscription)
  const [premierTime, setPremierTime] = useState(false)
  const [videoToggle, setVideoToggle] = useState(false)

  const handleButtonClick = () => {
    notify('В данный момент покупка недоступна', 'error')
  }

  const options = { hour: 'numeric', minute: 'numeric' }
  const today = new Date()
  const premier = new Date(premierDate)

  useEffect(() => {
    if (premier.getTime() <= today.getTime()) {
      setPremierTime(true)
    }
  }, [])

  return (
    <article className={clsx(styles.card, className)}>
      <div className={styles.poster}>
        <img
          src={`${process.env.REACT_APP_API_URL}/api/v1/static/posters/${poster}`}
          alt={title}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>
          <h3>{title}</h3>
        </div>
        {subTitle && <p>{subTitle}</p>}
        {/* {description && <p>{description}</p>} */}
        {!premierTime && (
          <div className={styles.countDown}>
            <h5>Премьера:</h5>
            <p>{premier.toLocaleDateString('ru-RU')}</p>
          </div>
        )}
      </div>
      <Views
        className={styles.views}
        counter={views}
      />
      <div className={styles.play}>
        {premierTime ? (
          <Link
            className={styles.play_btn}
            to={`/watch/${_id}`}
          >
            <img
              src={playIcon}
              alt='play'
            />
          </Link>
        ) : (
          <p>Ожидается премьера</p>
        )}
      </div>
      {/* <FsLightbox
        toggler={videoToggle}
        sources={[
          <div className={styles.player}>
            <VideoPlayer
              url={`${process.env.REACT_APP_API_URL}/api/v1/watch/${_id}`}
              poster={`${process.env.REACT_APP_API_URL}/api/v1/static/posters/${poster}`}
            />
          </div>,
        ]}
      /> */}
    </article>
  )
}

export default Card
